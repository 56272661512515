@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  html {
    font-family: "Poppins", sans-serif;
  }
}

body {
  scroll-behavior: smooth;
  overflow: hidden;
}
